// src/ComingSoon.js

import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useSpring, animated } from 'react-spring';
import './ComingSoon.css'; // Import the CSS for styling

const ComingSoon = () => {
  // Animation for the card
  const cardSpring = useSpring({
    from: { opacity: 0, transform: 'translateY(20px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: { duration: 800 },
  });

  return (
    <Container fluid className="coming-soon-container">
      <Row className="h-100 justify-content-center align-items-center">
        <Col xs={12} md={8} lg={6}>
          <animated.div style={cardSpring} className="coming-soon-card">
            <h1 className="display-4">Coming Soon!</h1>
            <p className="lead">Exciting new styles are just around the corner.</p>
            <p className="text-muted">Stay tuned for updates on our exclusive dress collection.</p>
            <Button variant="primary" href="#subscribe" className="mt-3">Subscribe for Updates</Button>
          </animated.div>
        </Col>
      </Row>
    </Container>
  );
};

export default ComingSoon;
